import { default as router } from '@/router';
import Vue from 'vue';
import wdm16 from '@/wdm16';
import nav from '@/_nav';

// URL and endpoint constants
import { API_BASE_URL, CLIENT_ID, CLIENT_SECRET, GRANT_TYPE } from '@/config';
const LOGIN_URL = API_BASE_URL + 'oauth/token';
const LOGOUT_URL = API_BASE_URL + 'oauth/logout';
// import LanguageService from '@/services/language';
import WmanagerService from '@/services/wmanager';
// import VersionService from '@/services/version';
// import GlobalService from '@/services/global';
import store from '@/store';

/* global localStorage */

export default {

  // User object will let us check authentication status
  user: {
    authenticated: false
  },

  // Send a request to the login URL and save the returned JWT
  login(context, creds, redirect) {
    return new Promise((resolve, reject) => {
      creds = Object.assign(creds, {
        'client_id': CLIENT_ID,
        'client_secret': CLIENT_SECRET,
        'grant_type': GRANT_TYPE,
        'scope': 'wisdom'
      });
      store.commit('Loading', { 'status': true, 'data': { 'label': 'Sistem verileri hazırlanıyor lütfen bekleyiniz.' } });
      WmanagerService.get_token('', creds)
        .then(resp => {
          if (resp.data.status_code === "3000" && resp.data.access_token && resp.data.refresh_token && resp.data.token_type) {
            this.user.authenticated = true;
            localStorage.setItem('access_token', resp.data.access_token);
            if (redirect) {
              router.push(redirect);
            }
            store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
            resolve('success');
          } else {
            store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
            reject('error');
          }
        }), resp => {
          store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
          reject('error');
        };
    });
  },

  logout(context, redirect) {
    // console.log(context, redirect);
    context.$http.post(LOGOUT_URL, { 'logout': true })
      .then(data => {
        // console.log(data);
        if (localStorage.getItem('user')) {
          localStorage.removeItem('user');
        }
        if (localStorage.getItem('access_token')) {
          localStorage.removeItem('access_token');
        }
        if (localStorage.getItem('account_list')) {
          localStorage.removeItem('account_list');
        }
        if (localStorage.getItem('profile_picture')) {
          localStorage.removeItem('profile_picture');
        }
        this.user.authenticated = false;
        if (redirect) {
          router.go(redirect);
        }
        // location.reload();
      }, response => {
        router.push(redirect);
        context.error = response.data.error;
      });
  },

  checkAuth() {
    var jwt = localStorage.getItem('access_token');
    if (jwt) {
      this.user.authenticated = true;
    } else {
      this.user.authenticated = false;
    }
  },

  // The object to be passed as a header for authenticated requests
  getAuthHeader() {
    return 'Bearer ' + localStorage.getItem('access_token');
  },

  // In Auth service
  refreshToken(context, request) {
    return new Promise(function(resolve, reject) {
      // Refresh token
      var creds = {
        'client_id': CLIENT_ID,
        'client_secret': CLIENT_SECRET,
        'grant_type': 'refresh_token',
        'refresh_token': 'token',
        'scope': 'wisdom'
      };
      Vue.http.post(LOGIN_URL, creds).then(response => {
        // Store refreshed token
        localStorage.setItem('access_token', response.data.access_token);
        // Resubmit original request and resolve the response (probably shouldn't be the responsibility of the Auth service...)
        Vue.http(request).then(function(newResponse) {
          resolve(newResponse);
        });
      }, function(newResponse) {
        reject(newResponse);
      });
    });
  },

  checkExpiredToken(response) {
    return new Promise(function(resolve, reject) {
      // If token is expired, refresh token, resubmit original request & resolve response for original request
      if (response.status === 401 && (response.data.error === 'token_expired' || response.data.error === 'token_invalid')) {
        if (localStorage.getItem('user')) {
          localStorage.removeItem('user');
        }
        if (localStorage.getItem('account_list')) {
          localStorage.removeItem('account_list');
        }
        if (localStorage.getItem('profile_picture')) {
          localStorage.removeItem('profile_picture');
        }
        if (localStorage.getItem('access_token')) {
          localStorage.removeItem('access_token');
          this.checkAuth();
        }
        router.push('/login');
      }
      // Otherwise just resolve the current response
      resolve(response);
    }.bind(this));
  },

  checkUnauthorizedToken(response, request_body) {
    // console.log('checkUnauthorizedToken ', response);
    if (response.status === 401 && response.statusText === 'Unauthorized') {
      if (localStorage.getItem('access_token')) {
        localStorage.removeItem('access_token');
        this.checkAuth();
      }
      if (request_body && request_body.to && request_body.token && request_body.refresh) {
        let new_redirect = request_body.to.fullPath;
        if (request_body.to.query.wisdom) {
          if (new_redirect.indexOf('&wisdom=' + request_body.to.query.wisdom) !== -1) {
            new_redirect = new_redirect.replace('&wisdom=' + request_body.to.query.wisdom, '');
          } else if (new_redirect.indexOf('wisdom=' + request_body.to.query.wisdom + '&') !== -1) {
            new_redirect = new_redirect.replace('wisdom=' + request_body.to.query.wisdom + '&', '');
          } else {
            new_redirect = new_redirect.replace('wisdom=' + request_body.to.query.wisdom, '');
          }
          delete request_body.to.query.wisdom;
        }
        if (request_body.to.query.modsiw) {
          if (new_redirect.indexOf('&modsiw=' + request_body.to.query.modsiw) !== -1) {
            new_redirect = new_redirect.replace('&modsiw=' + request_body.to.query.modsiw, '');
          } else if (new_redirect.indexOf('modsiw=' + request_body.to.query.modsiw + '&') !== -1) {
            new_redirect = new_redirect.replace('modsiw=' + request_body.to.query.modsiw + '&', '');
          } else {
            new_redirect = new_redirect.replace('modsiw=' + request_body.to.query.modsiw, '');
          }
          delete request_body.to.query.modsiw;
        }
        router.push('/login?new_url=' + new_redirect);
      } else {
        if (router.currentRoute.path !== '/welcome') {
          router.push('/welcome');
        }
      }
    }
  },

  last_wisdomera_version_control: function() {
    let my_version = JSON.parse(localStorage.getItem('version'));
    VersionService.last_wisdomera_version()
      .then(resp => {
        if (resp.data.status === 'success') {
          if (my_version && my_version.version) {
            if (resp.data.result && resp.data.result.version && my_version.version !== resp.data.result.version) {
              store.commit('version', { 'version_data': resp.data.result });
              localStorage.version = JSON.stringify(resp.data.result);
              console.log('last version changed ', my_version.version, ' => ', resp.data.result.version);
            } else {
              store.commit('version', { 'version_data': my_version });
              console.log('no version change');
            }
          } else {
            console.log('version information created: ', resp.data.result.version);
            store.commit('version', { 'version_data': resp.data.result });
            localStorage.version = JSON.stringify(resp.data.result);
          }
        } else {
          console.log('version error:', resp.data.message);
        }
      });
  },

/*  f_getWisdomeraSystemDocument: function() {
    GlobalService.get_one_cb_document({ 'bucket': 'default', 'key': 'wisdomera_system' })
      .then(resp => {
        if (resp.data.status === 'success') {
          localStorage.setItem('wisdomera_system', JSON.stringify(resp.data.result));
          // console.log(resp.data.result);
        } else {
          console.log('error ', resp.data.message);
        }
      });
  },
*/
  f_getWdm16IdMeaningWithLangId: function(i18n, type = 'default', lang = '') {
    // console.log('f_getWdm16IdMeaningWithLangId, type', type);
    let target_lang = 'en';
    let active_lang = localStorage.getItem('active_lang');
    if (active_lang) {
      target_lang = active_lang;
    }
    if (type === 'default') {
      let user = '';
      try {
        user = JSON.parse(localStorage.getItem('user'));
      } catch (err) {}
      if (user && user.active_lang) {
        target_lang = user.active_lang;
      }
    } else if (type === 'this') {
      if (lang) {
        target_lang = lang;
      }
    }
    // console.log('user.active_lang ', user.active_lang);
    let data = {
      'lang': target_lang,
      'wdm16_list': wdm16
    };
    LanguageService.get_wdm16_id_meaning_with_lang_id(data)
      .then(resp => {
        if (resp.data.status === 'success') {
          localStorage.setItem('active_lang', target_lang);
          // console.log(resp.data.result);
          // wdm16 = resp.data.result.wdm16_list;
          // console.log('nav', nav);
          // console.log(resp.data.result.wdm16_list.wdm16['4053']);
          // i18n.setLocaleMessage(target_lang, resp.data.result.wdm16_list);
          i18n.setLocaleMessage('wdm16', resp.data.result.wdm16_list);
          // console.log(i18n._vm);
          // we translate menu side bar items
          for (let i in nav.items) {
            let w_id = nav.items[i].wdm16_id;
            if (w_id && resp.data.result.wdm16_list['wdm16'][w_id]) {
              // console.log(resp.data.result.wdm16_list['wdm16'][w_id]);
              nav.items[i].name = resp.data.result.wdm16_list['wdm16'][w_id];
            }
            if (nav.items[i].children) {
              for (let c in nav.items[i].children) {
                let w_id = nav.items[i].children[c].wdm16_id;
                if (w_id && resp.data.result.wdm16_list['wdm16'][w_id]) {
                  nav.items[i].children[c].name = resp.data.result.wdm16_list['wdm16'][w_id];
                }
              }
            }
          }
          // i18n.locale = target_lang;
          i18n.locale = 'wdm16';
        } else {
          console.log('error, ', resp.data.message);
        }
      });
  }
};
