import Vue from 'vue';
import { API_WMANAGER_URL } from '@/config';
export default {
  layer_data_get(query) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/layer/data/get?' + query);
  },
  wdm_sentence_template_search(query, data) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/wdm/sentence/template/search?' + query, data);
  },
  get_sentence_template_schema(query, data) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/wdm/sentence/template/schema/get?' + query, data);
  },
  sentence_template_schema_list(query, data) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/wdm/sentence/template/schema/list?' + query, data);
  },
  save_sentence_template_schema(query, data) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/wdm/sentence/template/schema/save?' + query, data);
  },
  wdm_sentence_template_analyze(query, data) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/wdm/sentence/template/analyze?' + query, data);
  },
  wdm_sentence_template_record(query, data) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/wdm/sentence/template/record?' + query, data);
  },
  wdm_sentence_template_delete(query, data) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/wdm/sentence/template/delete?' + query, data);
  },
  wdm_sentence_template_list(query) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/wdm/sentence/template/list?' + query);
  },
  get_layer_wdmr(query) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/layer/wdmr/get?' + query);
  },
  get_layer_wdmr_and_wdm(query) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/layer/wdmr/and/wdm/get?' + query);
  },
  layer_wdmr_record(query, data) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/layer/wdmr/record?' + query, data);
  },
  layer_wdmr_save(query, data) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/layer/wdmr/save?' + query, data);
  },
  layer_wdmr_token(query, data) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/layer/wdmr/token?' + query, data);
  },
  save_this_wdmr_to_layer_by_wdmr_id_list(query, data) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/layer/wdmr/save/by_wdmr_id_list?' + query, data);
  },
  get_wdmr_list_by_wdmr_id(query, data) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/layer/wdmr/list/by_wdmr_id?' + query, data);
  },
  get_wdmr_list_and_layer_wdm_by_wdmr_id(query, data) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/layer/wdmr/list/and_layer_wdm/by_wdmr_id?' + query, data);
  },
  get_token(query, data) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/oauth/token?' + query, data);
  },
  check_token(query) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/oauth/token/check?' + query);
  },
  get_anatomy_model(query) {
    return Vue.http.get(API_WMANAGER_URL + 'v1/get_anatomy_model?' + query);
  },
  wdm_sentence_smart_search_model_list(query) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/wdm/sentence/smart_search_model/list?' + query);
  },
  wdm_sentence_smart_search_model_get_as_labeled(query, data) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/wdm/sentence/smart_search_model/get/as_labeled?' + query, data);
  },
  wdm_sentence_smart_search_model_details(query) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/wdm/sentence/smart_search_model/details?' + query);
  },
  layer_anatomy_add_anatomy_list(query, data) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/layer/anatomy/add?' + query, data);
  },
  layer_wdm_parameter_option_add(query, data) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/layer/wdm/parameter/option/add?' + query, data);
  },
  layer_smart_search_model_details_save(query, data) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/wdm/sentence/smart_search_model/details_save?' + query, data);
  },
  layer_smart_search_model_add_edit(query, data) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/wdm/sentence/smart_search_model/add_edit?' + query, data);
  },
  get_application_version(query) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/application/version?' + query);
  },
  schema_client_id_match_add_excel_file(query, data) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/schema/client_id/match/excel_file?' + query, data);
  },
  layer_schema_client_id_list_get(query) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/layer/schema/client_id/get/list?' + query);
  },
  layer_schema_client_id_list_save(query, data) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/layer/schema/client_id/save/list?' + query, data);
  },
  layer_sentence_list_work_get(query, data) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/layer/sentence/list/work/get?' + query, data);
  },
  layer_decision_making_by_wdmr_id(query, data) {
    return Vue.http.post(API_WMANAGER_URL + 'v1/layer/decision_making/by_wdmr_id?' + query, data);
  },
  get_request_ip() {
    return Vue.http.post(API_WMANAGER_URL + 'v1/get/request_ip');
  }
};
