<template>
  <div id="app">
    <router-view></router-view>
    <nprogress-container></nprogress-container>
  </div>
</template>

<script>
import nav from '@/_nav';
import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
// import NprogressContainer from 'vue-nprogress/src/NprogressContainer';
import wdm16 from './wdm16';
import auth from './auth';
export default {
  name: 'app',
  components: {
    // NprogressContainer
  },
  methods: {
    ...mapActions([
      'toggleDevice',
      'toggleSidebar'
    ])
  },
  mounted () {
    let user = JSON.parse(localStorage.getItem('user'));
    // this.$store.watch(state => state.app.lang, (lang) => {
    //   auth.f_getWdm16IdMeaningWithLangId(this.$i18n, 'default');
    // });
  },
  beforeMount () {
    const { body } = document;
    // console.log('body......', body);
    // console.log('document......', document);
    const WIDTH = 420;
    const RATIO = 3;

    const handler = () => {
      if (!document.hidden) {
        let rect = body.getBoundingClientRect();
        // console.log('rect...........', rect);
        // console.log('RECT.WİDTH....RATIO....WIDTH....', rect.width, RATIO, WIDTH);
        let isMobile = rect.width - RATIO < WIDTH;
        // console.log('isMobile....', isMobile);
        this.toggleDevice(isMobile ? 'mobile' : 'other');
        this.toggleSidebar(!isMobile);
        let screen_size = {
          'width': rect.width,
          'height': rect.height
        };
        // console.log(screen_size);
        this.$store.commit('screen_size', screen_size);
      }
    };

    document.addEventListener('visibilitychange', handler);
    window.addEventListener('DOMContentLoaded', handler);
    window.addEventListener('resize', handler);
    /* eslint no-extend-native: ["error", { "exceptions": ["Date"] }] */
  }
}

</script>

<style lang="scss">
/* Import Bootstrap Vue Styles */
@import 'node_modules/bootstrap/scss/bootstrap.scss';
@import 'node_modules/bootstrap-vue/src/index.scss';
// @import '~bootstrap-vue/dist/bootstrap-vue.css';

$fa-font-path: '~font-awesome/fonts/';
@import '~font-awesome/scss/font-awesome.scss';
@import '~flag-icon-css/css/flag-icon.min.css';
/* Import Main styles for this application */
@import 'assets/scss/style';

</style>

