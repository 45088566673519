import * as types from '../../mutation-types';
import technical from './routes/technical';
import tutorials from './routes/tutorials';
import terminology from './routes/terminology';
import howto from './routes/howto';
import haveyouseen from './routes/haveyouseen';
import why from './routes/why';
import contact from './routes/contact';

const state = {
  items: [
    technical,
    tutorials,
    terminology,
    howto,
    why,
    haveyouseen,
    contact
  ]
};

const mutations = {
  [types.EXPAND_MENU] (state, menuItem) {
    if (menuItem.index > -1) {
      if (state.items[menuItem.index] && state.items[menuItem.index].meta) {
        state.items[menuItem.index].meta.expanded = menuItem.expanded;
      }
    } else if (menuItem.item && 'expanded' in menuItem.item.meta) {
      menuItem.item.meta.expanded = menuItem.expanded;
    }
  }
};

export default {
  state,
  mutations
};
