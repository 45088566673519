// import lazyLoading from '../lazyLoading';

export default {
  name: 'haveyouseen',
  caption: 'Have you seen',
  wdm16_id: '',
  icon: 'icon-eye',
  path: '/haveyouseen',
  // component: lazyLoading('patient/index'),
  required: [],
  permissions: [],
  meta: {
    required: [],
    icon: 'fa-bars',
    expanded: false
  }
};
