import Vue from 'vue'
import Router from 'vue-router'
import menuModule from '@/store/modules/menu';

// Containers
const DefaultContainer = () => import('@/containers/DefaultContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')
// wisdomdocs
const WDMR_record = () => import('@/views/wdmr_record')
const fill_form = () => import('@/views/fill_form')
const WDMR_show = () => import('@/views/wdmr_show')
const WDMR_data_show = () => import('@/views/wdmr_data_show')
const WDMR_data_record = () => import('@/views/wdmr_data_record')
const Client_WDMR_data_record = () => import('@/views/client_wdmr_data_record')
const Welcome = () => import('@/views/pages/welcome')
const Terminology = () => import('@/views/docs/terminology')
const Howto = () => import('@/views/docs/howto')
const Why = () => import('@/views/docs/why')
const Haveyouseen = () => import('@/views/docs/haveyouseen')
const Contact = () => import('@/views/docs/contact')
const Algorithm = () => import('@/views/docs/algorithm')
const Subscription = () => import('@/views/docs/subscription')
const Technical = () => import('@/views/docs/technical')
const Tutorials = () => import('@/views/docs/tutorials')
const DocumentList = () => import('@/views/docs/documentlist')
const PermissionPackageList = () => import('@/views/docs/permissionpackagelist')
const DocumentTree = () => import('@/views/docs/documenttree')
const ShowOneDocument = () => import('@/views/docs/showonedocument')
const Video = () => import('@/views/docs/video')

const Login = () => import('@/views/Login')
const Logout = () => import('@/views/Logout')

Vue.use(Router)

export default new Router({
  // mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [{
      path: '/',
      redirect: 'welcome',
      name: 'Home',
      component: DefaultContainer,
      children: [{
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard,
          meta: {
            'web': 1
          }
        },
        {
          path: '/fill-form',
          name: 'fill_form',
          component: fill_form,
          meta: {
            'loginx': 1
          }
        },
        {
          path: '/wdmr-record',
          name: 'wdmr_record',
          component: WDMR_record,
          meta: {
            'loginx': 1
          }
        },
        {
          path: '/wdmr-show',
          name: 'wdmr_show',
          component: WDMR_show,
          meta: {
            'loginx': 1
          }
        },
        {
          path: '/wdmr-data-show',
          name: 'wdmr_data_show',
          component: WDMR_data_show,
          meta: {
            'loginx': 1
          }
        },
        {
          path: '/wdmr-data-record',
          name: 'wdmr_data_record',
          component: WDMR_data_record,
          meta: {
            'loginx': 1
          }
        },
        {
          path: '/client-wdmr-data-record',
          name: 'client_wdmr_data_record',
          component: Client_WDMR_data_record,
          meta: {
            'loginx': 1
          }
        },
        {
          path: '/welcome',
          name: 'welcome',
          component: Welcome,
          meta: {
            'loginx': 1
          }
        },
        {
          path: 'theme',
          redirect: '/theme/colors',
          name: 'Theme',
          component: {
            render(c) { return c('router-view') }
          },
          children: []
        },
        {
          path: '/documenttree',
          name: 'documenttree',
          component: DocumentTree,
          meta: {
            'loginx': 1
          }
        },
        {
          path: '/showonedocument',
          name: 'showonedocument',
          component: ShowOneDocument,
          meta: {
            'loginx': 1
          }
        },
        {
          path: '/video',
          name: 'video',
          component: Video,
          meta: {
            'loginx': 1
          }
        },
        {
          path: '/documentlist',
          name: 'documentlist',
          component: DocumentList,
          meta: {
            'loginx': 1
          }
        },
        {
          path: '/permissionpackagelist',
          name: 'permissionpackagelist',
          component: PermissionPackageList,
          meta: {
            'loginx': 1
          }
        },
        {
          path: '/algorithm',
          name: 'algorithm',
          component: Algorithm,
          meta: {
            'loginx': 1
          }
        },
        {
          path: '/subscription',
          name: 'subscription',
          component: Subscription,
          meta: {
            'loginx': 1
          }
        },
        {
          path: '/technical',
          name: 'technical',
          component: Technical,
          meta: {
            'loginx': 1
          }
        },
        {
          path: '/tutorials',
          name: 'tutorials',
          component: Tutorials,
          meta: {
            'loginx': 1
          }
        },
        {
          path: '/terminology',
          name: 'terminology',
          component: Terminology,
          meta: {
            'loginx': 1
          }
        },
        {
          path: '/howto',
          name: 'howto',
          component: Howto,
          meta: {
            'loginx': 1
          }
        },
        {
          path: '/why',
          name: 'why',
          component: Why,
          meta: {
            'loginx': 1
          }
        },
        {
          path: '/haveyouseen',
          name: 'haveyouseen',
          component: Haveyouseen,
          meta: {
            'loginx': 1
          }
        },
        {
          path: '/contact',
          name: 'contact',
          component: Contact,
          meta: {
            'web': 1
          }
        }
      ]
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/logout',
      name: 'Logout',
      component: Logout
    },
    {
      path: '*',
      name: 'welcome',
      component: Welcome
    }
  ]
})
